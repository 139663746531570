<template>
  <div @contextmenu.prevent>
    <b-row>
      <b-col
        cols="12"
        class="mt-2 mb-4"
      >
        <h1 class="text-center">{{ magazine.title }}</h1>
        <div class="pdf-container">
          <iframe  :src="url_pdf" type="application/pdf" width="100%" height="100%" onContextMenu="return false;" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      slug: '',
      url_pdf: '',
      url_api: process.env.VUE_APP_API,
      url_base: process.env.VUE_APP_API_BASE,
      magazine: {
        path: null,
        thumb: null,
        title: null
      }
    };
  },
  async mounted() {
    this.getMagazine();
  },
  created() {
    this.slug = this.$route.params.slug;
  },
  methods: {
    getMagazine() {
      axios
        .get(`${process.env.VUE_APP_API}/magazine/get`, {
          params: { slug: this.slug },
        })
        .then(response => {
          this.magazine.path = response.data.response.magazine.path;
          this.magazine.thumb = response.data.response.magazine.thumb;
          this.magazine.title = response.data.response.magazine.title;
          this.url_pdf =
            this.url_base +
            '/storage/magazine/pdf/' +
            this.magazine.path +
            '#view=FitH&toolbar=0';
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.pdf-container {
  max-width: 900px;
  width: 100%;
  height: 700px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
